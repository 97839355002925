<template>
    <section id="lead-validator">
        <h2>Sales Lead Validator</h2>
        <p>
            Please paste the job description into the input box below. This function validates whether the JD implies a demand for our FirmGPT and, if likely, 
            generates a pitch letter for copy-and-paste. For an even easier access to the function by submitting only the web address of the JD, please click 
            <a href="https://chatgpt.com/g/g-gHb0vKQtg-leads-validator" target="_blank" rel="noopener noreferrer" style="font-weight: bold;">here</a> 
            to open it in ChatGPT.
        </p>

        <iframe id="page-frame" :style="{ height: iframeHeight }" :src="initUrl"></iframe>
    </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export default {
    name: 'LeadvalView',
    setup() {
        const initUrl = `${process.env.VUE_APP_API_URL}/chat/html/aidi-func.html?com=LeadVal&p-url=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}`;    // p-url is the parent url for the html in the iframe, this is for security control that prevents talk to unknow source called the html

        /* let the JS in the <iframe> post a message that contains the document height, then change the height of the <iframe> here accordingly */
        /* this is mixed with 'about-us' toggle */
        const iframeHeight = ref('200px');   // Initialize with a default or initial height
        const handleIframeMessage = (event) => {
            if (event.origin === process.env.VUE_APP_API_URL && typeof event.data.frame_height === "number") {
                iframeHeight.value = `${event.data.frame_height + 10}px`;   // Update the height reactively
                setTimeout(() => {      // wait until the <iframe> is updated by ref, which is iframeHeight in this case
                    document.getElementById("page-frame").scrollIntoView({ behavior: 'smooth', block: 'end' });
                }, 50);
            }
        }

        onMounted(() => {
            document.getElementById('gpt-icon').style.display = 'none';
            document.getElementById('about-us').style.display = 'none';
            window.addEventListener('message', handleIframeMessage);   // Set up the event listener for receiving messages from <iframe>
        });

        onBeforeRouteLeave((to, from, next) => {
            document.getElementById('gpt-icon').style.display = 'block';
            document.getElementById('about-us').style.display = 'block';
            window.removeEventListener('message', handleIframeMessage);
            next();
        });

        return { initUrl, iframeHeight };
    }
}
</script>

<style scoped>
iframe {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
    overflow: scroll;    /* Always show scrollbars */
}
</style>