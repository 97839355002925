import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure this import is correct

/* add Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane, faMessage } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Adding icons to the library
library.add(faPaperPlane, faMessage, faPhone, faEnvelope);

//
createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app');
